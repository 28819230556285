import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTotal } from "../reducers/totalReducer";

const Subtitle = ({ reduction }) => {
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <h4>Yhteensä</h4>
            </td>
            <td>
              <div style={{ marginLeft: "20px", color: "green" }}>
                (sis. alennus -{reduction} %)
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const PriceLines = ({ value }) => {
  const dispatch = useDispatch();
  React.useEffect(() =>
    dispatch(changeTotal(value), [value])
  );
  const noVAT = (value / 1.255).toFixed(2);
  const withVAT = value.toFixed(2);
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <b>ALV 25.5%</b>
          </td>
          <td>
            <b>{withVAT}</b> eur
          </td>
        </tr>
        <tr>
          <td>ALV 0%</td>
          <td>{noVAT} eur</td>
        </tr>
      </tbody>
    </table>
  );
};

const Total = ({ songPrices }) => {
  const items = useSelector((state) => state.items);
  const formats = useSelector((state) => state.formats);
  const selectedFormat = formats.filter((format) => format.checked === true);
  const formatsTotalPrice = selectedFormat.length * 59;
  const priceWithVAT =
    // LessThanFiveMin
    items[0].amount * songPrices.LessThanFiveMin +
    // FiveToTenMin
    items[1].amount * songPrices.FiveToTenMin +
    // TenToTwentyMin
    items[2].amount * songPrices.TenToTwentyMin +
    // OverTwentyMin
    items[3].amount * songPrices.OverTwentyMin +
    // selected formats
    formatsTotalPrice;
  if (priceWithVAT >= 150 && priceWithVAT < 294) {
    return (
      <>
        <Subtitle reduction={10} />
        <PriceLines value={priceWithVAT * 0.9} />
      </>
    );
  }
  if (priceWithVAT >= 294 && priceWithVAT < 500) {
    return (
      <>
        <Subtitle reduction={15} />
        <PriceLines value={priceWithVAT * 0.85} />
      </>
    );
  }
  if (priceWithVAT >= 500 && priceWithVAT < 750) {
    return (
      <>
        <Subtitle reduction={20} />
        <PriceLines value={priceWithVAT * 0.8} />
      </>
    );
  }
  if (priceWithVAT >= 750) {
    return (
      <>
        <Subtitle reduction={25} />
        <PriceLines value={priceWithVAT * 0.75} />
      </>
    );
  } else {
    return (
      <div>
        <b>Yhteensä</b>
        <table>
        </table>
        <PriceLines value={priceWithVAT} />
      </div>
    );
  }
};

export default Total;
